<template>
    <div>
        <btn
            size="md:text-sm text-xs px-5 py-1 rounded min-w-[150px] max-w-[250px]"
            color="border border-red-500 text-orange-600"
            @onClick="openDialog">
            <i class="el-icon-plus text-lg"></i>
            新增服務商
        </btn>
        <el-dialog
            v-if="showDialog"
            :visible.sync="showDialog"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            :show-close="false"
            width="500px"
            @closed="closedDialog"
            @close="closeDialog">
            <span class="cursor-pointer text-2xl absolute top-5 right-10" @click="closeDialog"><i class="icofont-close-line"></i></span>
            <div>
                <h3 class="text-center text-gray-400 text-xl mb-5">新增服務商</h3>
                <p class="text-center text-light text-gray-300 text-base mb-10">請輸入服務商手機號碼</p>
            </div>
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item v-loading="loading" prop="mobile">
                    <div class="flex">
                        <el-select v-model="form.countryCode" class="flex-shrink w-40" placeholder="國碼">
                            <el-option v-for="item in ['886']" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                        <el-input v-model="form.mobile" class="flex-grow" placeholder="請輸入手機號碼" @input="onInput"> </el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div class="flex justify-center">
                <btn :disabled="disabled" size="md:text-sm text-xs px-3 py-2 rounded-lg min-w-[80px] max-w-[80px]" @onClick="add()"> 新增 </btn>
                <!-- <button
                    :disabled="disabled"
                    class="bg-black rounded-full text-white py-2 w-[200px] disabled:bg-gray-100 disabeld:text-white disabled:cursor-not-allowed"
                    @click.prevent="add()">
                    新增
                </button> -->
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

export default {
    components: {
        Btn,
    },
    data() {
        return {
            form: {
                countryCode: "886",
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        message: "輸入搜尋手機號碼",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: `手機號碼必須為數字`,
                        trigger: "blur",
                    },
                    { validator: this.formPhoneSearchUser, trigger: "blur" },
                ],
            },
            // 旋轉動畫
            loading: false,
            // 顯示彈窗
            showDialog: false,
            // 判斷是否可以點擊新增幹鈕
            disabled: true,
            providerId: null,
        };
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),
        // 輸入中檢查
        onInput(val) {
            // 當手機號碼欄位為空
            if (this.$isEmpty(val)) {
                this.disabled = true;
            }
            // 當手機號碼欄位非數字時
            if (!val.match(/^[0-9]*$/)) {
                this.disabled = true;
            }
        },
        // 使用手機號碼搜尋供給者
        async formPhoneSearchUser(rule, value, callback) {
            // 判斷信箱是否為空值 或 信箱格式是否正確
            if (value !== "") {
                if (value.length > 0 && value[0] == 0) {
                    this.$set(this.form, "mobile", value.substring(1));
                }
                this.loading = true;
                try {
                    const { data } = await this.$api.UserSearchByPhone(this.form.countryCode + this.form.mobile);
                    // 判斷身份是否為服務商 （服務商身份 role = 1或2)
                    if (data.role > 0) {
                        // 取消旋轉動畫
                        this.loading = false;
                        //  disabled
                        this.disabled = true;
                        return callback(new Error("該號碼已是服務商"));
                    }
                    this.providerId = data.id;
                    // 取消旋轉動畫
                    this.loading = false;
                    // 取消 disabled
                    this.disabled = false;
                    return callback();
                } catch (err) {
                    // 取消旋轉動畫
                    this.loading = false;
                    // 開啟 disabled
                    this.disabled = true;
                    return callback(new Error("找不到用戶"));
                }
            } else {
                // 取消旋轉動畫
                this.loading = false;
                // 開啟 disabled
                this.disabled = true;

                return callback(new Error("輸入搜尋手機號碼"));
            }
        },
        // 開啟彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 關閉彈窗 與關閉searchBar 共用事件
        closeDialog() {
            this.form = {
                countryCode: "886",
            };
            this.showDialog = false;
        },
        // 彈窗關閉時 清空表單驗證
        closedDialog() {
            this.$refs.form.resetFields();
        },
        // 新增供給者
        add() {
            this.showDialog = false;
            this.$router.push({
                name: "provider_add",
                params: { id: this.providerId },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
    @apply border-b border-gray-200 border-0 rounded-none;
}
</style>
